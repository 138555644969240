<template>
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.0282" cy="14.1578" r="13.5561" transform="rotate(-90 14.0282 14.1578)" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCircleFilled'
}
</script>
